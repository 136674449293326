<template>
    <v-container style="max-width: 420px;">
        <v-card class="text-center">
            <v-card-text style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div
                    class="
                        text-h4
                        mb-3
                        font-weight-bold
                        text-center
                        black--text
                    "
                >
                    {{ $t('Aktivate uživatelského účtu') }}
                </div>
                <p class="mb-0" style="font-size: 1.2em; color: black;">
                    {{ $t('Na zadaný e-mail byl odesláno aktivační heslo. Vložte ho prosím do pole níže, tím se Vám účet aktivuje.') }}
                </p>
                <v-text-field v-model="token" placeholder="XXXXXXXX" outlined style="margin-top: 24px; max-width: 118px;"></v-text-field>
                <v-btn style="width: 100%; font-weight: 900 !important;" block color="green" dark x-large
                               class="font-weight-bold"
                               @click="activate()">
                            {{ $t('Aktivovat') }}
                        </v-btn>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import {URL} from "@/store/index";

export default {
    data() {
        return {
            token: ""
        }
    },
    methods: {
        activate() {
            let token = this.token;
            let url = URL + `user/activate/${token}`;

            const requestBody = {
                // Přizpůsobte podle API požadavků
                token: token,
            };

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            })
            .then((response) => {
                if (!response.ok) {
                    //alert('Špatný aktivační kód.');
                    return Promise.reject("Špatný aktivační kód.");
                }

                return response.json();
            })
            .then((data) => {
                console.log("Aktivace úspěšná:", data);
                alert('Aktivace proběhla úspěšně.');
                this.$router.push({name: "Home"});
            })
            .catch((error) => {
                alert('Špatný aktivační kód.');
            });
        }
    },
    mounted() {
        window.history.replaceState(null, '', '/');
    }
};
</script>