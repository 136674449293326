<template>
    <v-dialog v-if="user === undefined" v-model="dialogVar" transition="dialog-bottom-transition" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn plain v-bind="attrs" v-on="on" style="font-weight: bold; color: black;">
                {{ $t('Přihlásit se') }}
            </v-btn>
        </template>
        <v-card dark class="logindialog">

            <div style="display: flex; width: 100%; justify-content: space-between;">
                <v-card-title style="margin-bottom: 12px;">{{ $t('Přihlášení') }}</v-card-title>

                    <div @click="dialogVar = false" class="esy-close-button" style="color: white !important; width: 32px; height: 32px; margin: 16px; display: flex; justify-content: center; align-items: center;">
                        <svg style="width: 24px; height: 24px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>

            </div>

            <v-card-text>
                <v-alert color="error" v-model="error">
                    {{ $t('Takovou kombinaci e-mailu a hesla jsme nenašli. Zkus to prosím znovu.') }}
                </v-alert>

                <v-container @keyup.enter="logIn()" style="padding: 0;">
    
                    <v-text-field :label="$t('Email')" v-model="email" outlined required></v-text-field>
            
                    <v-text-field :label="$t('Heslo')" type="password" v-model="password" outlined required>
                    </v-text-field>
                
                    <reset></reset>

                </v-container>

                <div class="d-flex flex-column">
                    <v-btn color="green" @click="logIn()" :disabled="facebookLoading" :loading="loading" class=" font-weight-black">
                        {{ $t('Přihlásit se') }}
                    </v-btn>
                    <p class="text-center my-3">{{ $t('Nebo') }}</p>
                    <v-btn class="d-inline d-sm-none" color="#37639A" @click="facebook" :disabled="loading" :loading="facebookLoading">
                        <v-icon>fab fa-facebook</v-icon>
                    </v-btn>
                    <v-btn class="d-none d-sm-inline font-weight-black" color="#37639A" @click="facebook" :disabled="loading" :loading="facebookLoading">
                        {{ $t('Přihlásit se přes facebook') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
const { URL } = require("../../store/index");
import Reset from "../auth/Reset.vue";

export default {
    components: {
        Reset,
    },

    data() {
        return {
            dialogVar: false,
            loading: false,
            facebookLoading: false,

            forceRedirect: true,

            email: "",
            password: "",

            error: false,
        };
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
    },

    mounted() {
        this.$root.$on("openLoginDialog", (forceRedirect = true) => {
            this.dialogVar = true;
            this.forceRedirect = forceRedirect;
        })
    },

    methods: {
        facebook() {
            this.facebookLoading = true;
            window.location.href = this.$store.getters["facebookLoginUrl"];
        },
        async logIn() {
            this.loading = true;
            this.error = false;

            const response = await fetch(URL + "auth/in", {
                method: "POST",
                cors: "cors",
                body: JSON.stringify({
                    email: this.email,
                    password: this.password,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if (!response.ok) {
                this.loading = false;
                this.error = true;
                return;
            }

            const data = (await response.json()).data;

            this.$store.dispatch("setToken", data.token);
            this.$store.dispatch("loadUser");
            this.dialogVar = false;
            this.loading = false;


            window.history.replaceState(null, '', '/');

            if (this.forceRedirect) {
                if (this.$vuetify.breakpoint.smAndDown) {
                    this.$router.push({ name: "Home" });
                } else {
                    this.$router.push({ name: "Profile" });
                }
            }
        },
    },
};
</script>

<style>
.logindialog .v-btn {
    color: white;
    height: 50px !important;
}
.logindialog {
    background-color: #1e1e1e !important;
}

.esy-close-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .25) !important;
}
</style>